@font-face {
  font-family: 'Js Jindara';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Jindara.ttf') format('truetype');
}

@font-face {
  font-family: 'Js Jindara';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Jindara-Bold.ttf') format('truetype');

}

@font-face {
  font-family: 'Js Jindara';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/Jindara-Italic.ttf') format('truetype');
}