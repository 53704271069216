@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-datepicker/dist/react-datepicker.css';
@import 'cropperjs/dist/cropper.css';
@import '@reach/tooltip/styles.css';
@import './fonts/js-jindara.css';
/* @import './fonts/js-arisa.css'; */

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Sarabun', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  scrollbar-color: #3f54d9 #eeeeee;
  scrollbar-width: thin;
}

.custom-night-mode .react-datepicker__month-container {
  @apply !bg-dark-10;
}
.custom-night-mode .react-datepicker__header {
  @apply !bg-gray-16;
}
.custom-night-mode
  .react-datepicker__year-dropdown.react-datepicker__year-dropdown--scrollable {
  @apply !bg-gray-14;
}
.custom-night-mode.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::after {
  @apply !border-t-dark-10;
}

.react-datepicker__triangle {
  left: -50px !important;
}

.circle-crop .cropper-view-box {
  border-radius: 50%;
}
.crop-image .cropper-modal {
  background-color: #fafafa;
  opacity: 0.9;
}

.dark .crop-image .cropper-modal {
  background-color: #2d2d2d !important;
}
.dark .crop-image .cropper-modal.night-mode {
  background-color: #2d2d2d !important;
}

.faq ul {
  display: block;
  list-style-type: disc;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 80px;
}
.faq ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.faq li {
  display: list-item;
}
.faq h1 {
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 16px;
}
.faq img {
  margin: auto;
}

.about-coin ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

/* hide arrow input type number */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* show placeholder text cutoff input type text */
input::placeholder {
  overflow: visible;
}

@layer components {
  .topic-base {
    @apply text-[24px] font-medium text-secondary;
  }

  .topic-small {
    @apply text-[18px] font-medium text-secondary;
  }

  .border-rounded {
    @apply border-[1px] border-gray-3 rounded-[8px];
  }

  .card {
    @apply border border-gray-4 shadow-card rounded-[8px] mobile:border-0 mobile:shadow-none;
  }
}

@layer utilities {
  .scrollbar-thumb-secondary::-webkit-scrollbar-thumb {
    @apply bg-secondary;
  }
  .scrollbar-thumb-secondary-1::-webkit-scrollbar-thumb {
    @apply bg-secondary-1;
  }
}

/* style progress */
progress[value]::-webkit-progress-bar {
  border-radius: 40px;
  background-color: #fff;
}

progress[value]::-webkit-progress-value {
  background-color: #3f54d9;
  border-radius: 40px;
}

/* end style progress */

.scrollbar-none {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.scrollbar-none::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.break-word {
  word-break: break-word;
}

::-webkit-scrollbar {
  background-color: #eeeeee;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  width: 6px;
  height: 6px;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #3f54d9;
  width: 6px;
  height: 6px;
  border-radius: 3px;
}

/* Search sepia theme */
.sepia-mode > .search-input input {
  background-color: #f8f6f0;
}

.sepia-mode > .search-input .search-dropdown {
  background-color: #f8f6f0;
}

.sepia-mode > .search-input .search-dropdown > div:hover {
  background-color: #f0ede6;
}

/* Search night theme */
.night-mode > .search-input input {
  background-color: #222222;
  border: 1px solid #343434;
  color: white;
}

.night-mode > .search-input input:focus {
  color: #3f54d9;
}

.night-mode > .search-input .search-dropdown {
  background-color: #222222;
}

.night-mode > .search-input .search-dropdown > div {
  color: white;
}

.night-mode > .search-input .search-dropdown > div:hover {
  background-color: #111111;
  color: #3f54d9;
}

/* Search dark theme */
.dark-mode > .search-input input {
  background-color: #191919;
  border: 1px solid #343434;
  color: #767676;
}

.dark-mode > .search-input input::placeholder {
  color: #343434;
}

.dark-mode > .search-input input:focus {
  color: #3f54d9;
}

.dark-mode > .search-input .search-dropdown {
  background-color: #191919;
}

.dark-mode > .search-input .search-dropdown > div {
  color: #767676;
}

.dark-mode > .search-input .search-dropdown > div:hover {
  background-color: #000000;
  color: #3f54d9;
}

#becookies-float-message-animation-wrapper {
  z-index: 1000;
}
