.hide-scroll-drawer {
    overflow: hidden;
    padding-right: 6px;
}

.hide-scroll-modal {
    overflow: hidden;
    padding-right: 0;
}

@media screen and (max-width: 719px) {
    .hide-scroll-drawer {
        overflow: hidden;
        padding-right: 0;
    }

    .hide-scroll-modal {
        overflow: hidden;
        padding-right: 0;
    }
}
