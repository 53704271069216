/* @import 'swiper/css'; */
@import 'swiper/css/bundle';
@import 'swiper/css/grid';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide {
  width: fit-content;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #cecfd7;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #3f54d9;
}

.recommended-swiper .swiper-slide {
  -webkit-box-align: flex-start !important;
  -ms-flex-align: flex-start !important;
  -webkit-align-items: flex-start !important;
  align-items: flex-start !important;
}

.recommended-swiper .swiper-pagination-bullet-active {
  width: 40px;
  border-radius: 40px;
}

@media (max-width: 768px) {
  .recommended-swiper .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 10px;
  }
}

.swiper-button-disabled {
  display: none;
}

.swiper-content-first .swiper-slide, .swiper-content-second .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

@media (max-width: 719) {
  .swiper-content-second .swiper-slide-active {
    width: 348px !important;
  }
}

@media (min-width: 720px) {
  .swiper-content-second .swiper-slide-active {
    width: 464px !important;
  }
}

@media (min-width: 1280px) {
  .swiper-content-second .swiper-slide-next {
    width: 928px !important;
  }
}
