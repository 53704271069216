.rc-pagination {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.rc-pagination ul,
.rc-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-pagination-item {
  display: inline-block;
  min-width: 30px;
  height: 30px;
  margin-right: 12px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #f4f4f7;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  user-select: none;
  font-weight: 300;
  font-size: 12px;
  font-family: 'Sarabun', sans-serif;
  color: #0c1135;
}

.rc-pagination-item-active {
  color: #fff;
  background: #0c1135;
}

.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  outline: 0;
}
.rc-pagination-jump-prev button,
.rc-pagination-jump-next button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}
.rc-pagination-jump-prev button:after,
.rc-pagination-jump-next button:after {
  display: block;
  content: '...';
}
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  display: inline-block;
  min-width: 8px;
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.rc-pagination-disabled,
.rc-pagination-disabled:hover,
.rc-pagination-disabled:focus {
  cursor: not-allowed;
}

@media only screen and (max-width: 992px) {
  .rc-pagination-item-after-jump-prev,
  .rc-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .rc-pagination-options {
    display: none;
  }
  .rc-pagination-item {
    margin-right: 10px;
  }
}

.rc-pagination-prev.rc-pagination-disabled > div,
.rc-pagination-next.rc-pagination-disabled > div {
  color: #f4f4f7;
  border: 1px solid #f4f4f7;
}

.rc-pagination-prev > div,
.rc-pagination-next > div {
  color: #0c1135;
  border: 1px solid #f4f4f7;
}

/* Night mode */
.night-mode .rc-pagination-prev.rc-pagination-disabled > div,
.night-mode .rc-pagination-next.rc-pagination-disabled > div {
  color: #858baf;
  border: 1px solid #858baf;
  opacity: 0.3;
}

.night-mode .rc-pagination-prev > div,
.night-mode .rc-pagination-next > div {
  color: #bcbcbc;
  border: 1px solid #bcbcbc;
}

.night-mode .rc-pagination-item {
  background-color: #343434;
  color: #bcbcbc;
}

.night-mode .rc-pagination-item.rc-pagination-item-active {
  background-color: #bcbcbc;
  color: #0c1135;
}
/* Night mode end*/

/* Dark mode */
.dark-mode .rc-pagination-prev.rc-pagination-disabled > div,
.dark-mode .rc-pagination-next.rc-pagination-disabled > div {
  opacity: 0.3;
}

.dark-mode .rc-pagination-prev > div,
.dark-mode .rc-pagination-next > div {
  color: #767676;
  border: 1px solid #767676;
}

.dark-mode .rc-pagination-item {
  background-color: #343434;
  color: #767676;
}

.dark-mode .rc-pagination-item.rc-pagination-item-active {
  background-color: #767676;
  color: #22284a;
}
/* Dark mode end*/



