/* tiptap css */
.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror {
  padding: 10px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 300;
  min-height: 300px;
}

.custom-night-mode .ProseMirror {
  border: 1px solid #292b44;
}

.ProseMirror blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 30px;
  border-left: 3px solid #eee;
}

.ProseMirror ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.ProseMirror li {
  display: list-item;
}
.ProseMirror li p {
  text-indent: 0 !important;
}
.ProseMirror ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.ProseMirror mark {
  color: inherit;
}

.webview {
  height: 100vh;
}

.webview .ProseMirror {
  padding: 0 20px;
  font-size: 16px;
  border: none;
  font-weight: 300;
}

.ProseMirror a {
  color: #3f54d9;
  cursor: pointer;
  text-decoration: underline;
}

.tiptap-viewer .ProseMirror {
  border: none;
  min-height: max-content;
}

.tiptap-edit .ProseMirror {
  height: 200px;
  overflow-y: auto;
}

.webview .tiptap-edit .ProseMirror {
  height: 100%;
}

.webview > div {
  overflow-y: auto;
}

.tiptap-edit .ProseMirror p {
  text-indent: 10%;
}

.tiptap-edit .ProseMirror li * {
  text-align: left !important;
  text-indent: 0 !important;
}

.tiptap-edit .ProseMirror p[style*='text-align: center'],
.tiptap-edit .ProseMirror p[style*='text-align: right'],
.tiptap-edit .ProseMirror p[style*='text-align: justify'] {
  text-indent: 0;
}

.tiptap-edit .ProseMirror p img {
  max-width: 80%;
}

.tiptap-edit .ProseMirror::-webkit-scrollbar {
  background-color: #eeeeee;
  width: 8px;
  height: 8px;
}

.ProseMirror img {
  display: inline-block;
}

.tiptap-edit .ProseMirror img[alt~='sticker'] {
  width: 100px;
  height: 100px;
  vertical-align: initial;
}

.ProseMirror strong {
  font-weight: 700;
}

.ProseMirror.ProseMirror-focused {
  outline: none;
}

/* spoiler */
.spoiler-container {
  border: 1px solid #0d0d0d20;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;
}

.spoiler-container .title {
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  padding: 10px;
}

.spoiler-container .content {
  padding: 0.5rem;
  border-top: 1px solid #0d0d0d20;
}

/* iframe */
.iframe-wrapper {
  position: relative;
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: #f4f4f7;
}

.iframe-wrapper .ProseMirror-selectednode {
  outline: 3px solid #68cef8;
}

.iframe-wrapper iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}

/* footnote */
.toc {
  background: rgba(black, 0.1);
  border-radius: 0.5rem;
  opacity: 0.75;
  padding: 0.75rem;
}

/* comment */
.tiptap-comment-input .ProseMirror {
  width: 90%;
  min-height: 40px;
}

.tiptap-ebook-comment-input .ProseMirror {
  min-height: 79px;
}

.tiptap-comment-input .ProseMirror,
.tiptap-ebook-comment-input .ProseMirror {
  font-size: 14px;
  border: none;
  height: max-content;
  border-radius: 20px;
  padding-left: 50px;
  padding-right: 35px;
  overflow-y: auto;
  max-height: 400px;
}

.tiptap-comment-input .ProseMirror p,
.tiptap-ebook-comment-input .ProseMirror p {
  line-height: 1.8;
}

@media (max-width: 719px) {
  .tiptap-comment-input .ProseMirror,
  .tiptap-ebook-comment-input .ProseMirror {
    height: 176px;
    width: 100%;
    padding-right: 10px;
    margin-bottom: 40px;
    padding-top: 0;
    overflow-y: auto;
    max-height: 200px;
  }

  .tiptap-comment-input .ProseMirror {
    padding-left: 10px;
  }

  .tiptap-ebook-comment-input .ProseMirror {
    min-height: 79px;
    height: max-content;
  }
}

.tiptap-comment-input .ProseMirror p img,
.tiptap-ebook-comment-input .ProseMirror p img,
.tiptap-comment .tiptap-viewer .ProseMirror p img {
  width: 159px;
  height: 159px;
  vertical-align: initial;
}

.tiptap-comment-input .ProseMirror::-webkit-scrollbar,
.tiptap-ebook-comment-input .ProseMirror::-webkit-scrollbar {
  background-color: #eeeeee;
  width: 8px;
  height: 8px;
}

.tiptap-viewer .ProseMirror p {
  text-indent: 10%;
}

.tiptap-viewer .ProseMirror p[style*='text-align: center'],
.tiptap-viewer .ProseMirror p[style*='text-align: right'],
.tiptap-viewer .ProseMirror p[style*='text-align: justify'] {
  text-indent: 0;
}

.tiptap-viewer .ProseMirror p img {
  max-width: 80%;
}

.tiptap-viewer .ProseMirror img[alt~='sticker'] {
  width: 100px;
  height: 100px;
  vertical-align: initial;
}

.tiptap-viewer.night-mode p,
.tiptap-viewer.night-mode span,
.tiptap-viewer.night-mode div,
.tiptap-viewer.night-mode a {
  color: #ffffff !important;
}

.tiptap-viewer.sepia-mode p,
.tiptap-viewer.sepia-mode span,
.tiptap-viewer.sepia-mode div,
.tiptap-viewer.sepia-mode a {
  color: #0c1135 !important;
}

.tiptap-viewer.dark-mode p,
.tiptap-viewer.dark-mode span,
.tiptap-viewer.dark-mode div,
.tiptap-viewer.dark-mode a {
  color: #767676 !important;
}

.tiptap-viewer.custom-night-mode span[style*='color: rgb'],
.tiptap-viewer.custom-night-mode span[style*='color: rgba'],
.tiptap-viewer.custom-night-mode span[style*='color: #'] {
  color: inherit !important;
}

.dark-mode img {
  opacity: 0.5;
}

.tiptap-comment .tiptap-viewer .ProseMirror {
  min-height: min-content;
  padding: 0;
}

.tiptap-comment .tiptap-viewer .ProseMirror p {
  text-indent: 0 !important;
  font-size: 14px;
}

/* show placeholder */
.ProseMirror p.is-editor-empty:first-child::before {
  color: #858baf;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  text-indent: 0;
}

.dark-mode .ProseMirror p.is-editor-empty:first-child::before {
  color: #343434;
}

.ProseMirror .is-empty.is-editor-empty {
  font-size: 14px;
}

.tiptap-intro .ProseMirror {
  min-height: 500px !important;
}

.story-reader .ProseMirror {
  padding: 0px;
}

/* story detail */
.story-detail .ProseMirror {
  padding: 0;
}

.chapter-content .ProseMirror,
.message-from-writer .ProseMirror {
  padding: 0;
}

.chapter-content > .tiptap-viewer,
.message-from-writer > .tiptap-viewer {
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
}

.font-14 .ProseMirror {
  font-size: 14px;
}
.font-16 .ProseMirror {
  font-size: 16px;
}
.font-18 .ProseMirror {
  font-size: 18px;
}
.font-20 .ProseMirror {
  font-size: 20px;
}
.font-22 .ProseMirror {
  font-size: 22px;
}
.font-24 .ProseMirror {
  font-size: 24px;
}
.font-14.is-js-font .ProseMirror {
  font-size: 20px;
}
.font-16.is-js-font .ProseMirror {
  font-size: 24px;
}
.font-18.is-js-font .ProseMirror {
  font-size: 26px;
}
.font-20.is-js-font .ProseMirror {
  font-size: 30px;
}
.font-22.is-js-font .ProseMirror {
  font-size: 32px;
}
.font-24.is-js-font .ProseMirror {
  font-size: 34px;
}

.line-height-125 .ProseMirror {
  line-height: 1.25;
}
.line-height-15 .ProseMirror {
  line-height: 1.5;
}
.line-height-2 .ProseMirror {
  line-height: 2;
}
.line-height-25 .ProseMirror {
  line-height: 2.5;
}
.line-height-3 .ProseMirror {
  line-height: 3;
}

.ProseMirror p:before {
  content: ' ';
}
.ProseMirror p:not(.is-editor-empty) .ProseMirror-trailingBreak {
  content: ' ';
  margin-top: 0;
  margin-bottom: 0;
}
